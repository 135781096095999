import React, {memo, useEffect, useRef, useState} from 'react';
import {NodeProps, Position, useUpdateNodeInternals} from 'reactflow';
import styles from './DefaultCustomNode.module.scss';
import {CustomNodeStatus, INodeData} from "../../interfaces/d";
import {getImageURLbyCheckSum, uploadImageByImageLoader} from '../../services/flowService';
import CustomHandle from "./CustomHandle";
import NodeManager from "./NodeManager";
import {Tooltip} from "react-tooltip";
import ImageWithFallback from "./ImageFallBack";
import {APP_PROXY_PORT} from "../../config/defaultConfig";
import {createPortal} from "react-dom";
import DragZoomSingle from "../DragZoomSingle";

const ImageLoaderComponent: React.FC<NodeProps<INodeData>> = ({data}) => {
    const updateNodeInternals = useUpdateNodeInternals();

    const nodeRef = useRef<HTMLDivElement>(null);
    const [imageUrlInput, setImageUrlInput] = useState('');
    const [nodeState, setNodeState] = useState<CustomNodeStatus>(data.status);

    const initialPreviewUrl = typeof data.parameters?.image_thumbnail_url === 'string' ? data.parameters.image_thumbnail_url : null;
    const [previewUrl, setPreviewUrl] = useState<string | null>(initialPreviewUrl);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0); // 업로드 진행 상태
    const [fileName, setFileName] = useState(''); // 파일명 상태

    const [isUploading, setIsUploading] = useState(false);  // 업로드 중인지 상태를 나타내는 변수
    const [uploadComplete, setUploadComplete] = useState(false);  // 업로드 완료 상태

    const handleCloseClick = () => {
        if (data.onClose) {
            data.onClose(data.id);
        }
    }

    useEffect(() => {
        if (typeof data.parameters?.image_thumbnail_url === 'string') {
            setPreviewUrl(data.parameters.image_thumbnail_url);
            setNodeState(CustomNodeStatus.COMPLETED);
        }
    }, [data.parameters?.image_thumbnail_url]);

    const resetNodeData = () => {
        setPreviewUrl(null);
        setNodeState(CustomNodeStatus.PREPARING);
        data.onChange({
            ...data,
            status: CustomNodeStatus.PREPARING,
            parameter_values: {
                ...data.parameter_values,
                image_id: null,
            },
        });
    };

    const handleImageUrlChange = async (e: React.MouseEvent) => {
        e.stopPropagation();

        if (imageUrlInput) {
            await handleImageSubmit(imageUrlInput);
        }
    };

    const handleImageSubmit = async (imageSource: string | File) => {
        resetNodeData();
        setIsUploading(true);
        setUploadComplete(false);

        await data.onChange({
            id: data.id,
            status: CustomNodeStatus.PROCESSING,
        });
        setNodeState(CustomNodeStatus.PROCESSING);

        let imageFile: File | null = null;
        let imageBlob = null;
        let newPreviewUrl = null;
        let newImageID: string = '';

        // Proxy server for CORS
        let proxyUrl = 'http://localhost:9001/';
        if (process.env.NODE_ENV === 'production') {
            proxyUrl = '/proxy/';
        } else {
            proxyUrl = `http://localhost:${APP_PROXY_PORT}/`;
        }

        if (typeof imageSource === 'string') {
            const response = await fetch(`${proxyUrl}${imageSource}`, {
                method: 'GET',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            });

            if (!response.ok) {
                resetNodeData();
                throw new Error('Network response was not ok.');
            }
            imageBlob = await response.blob();
            imageFile = new File([imageBlob], "image.jpg", {type: imageBlob.type});
            newPreviewUrl = URL.createObjectURL(imageBlob);
        } else {
            // Use blob from file input
            imageFile = imageSource;
            imageBlob = imageSource;
            newPreviewUrl = await readFileAsDataURL(imageBlob);
        }

        // Checksum from Blob
        const checksum = await calculateSHA1(imageBlob);

        const result = await getImageURLbyCheckSum(checksum);

        // Todo: remove url prefix and replace url by thumbnail_url
        const URL_PREFIX = 'https://dev.cnaps.ai';

        if (result && result.exist && result.url) {
            newPreviewUrl = URL_PREFIX + result.url.url;
            newImageID = result.image_id;
            setUploadComplete(true);
            setIsUploading(false);
            console.log('Already uploaded image:', newPreviewUrl);

        } else if (result && !result.exist && imageFile) {
            try {
                const uploadResult = await uploadImageByImageLoader(imageFile);
                if (uploadResult) {
                    setUploadComplete(true);  // 업로드가 성공적으로 완료되면 상태 업데이트
                    setIsUploading(false);  // 업로드 완료 후 업로드 중 상태를 false로 설정

                    newPreviewUrl = URL_PREFIX + uploadResult.url.url;
                    newImageID = uploadResult.image_id;
                    console.log('Image uploaded successfully:', newPreviewUrl);
                } else {
                    console.error('Error during image upload:', uploadResult);
                    setIsUploading(false);  // 업로드 실패 시
                    resetNodeData();
                    return;
                }
            } catch (error) {
                console.error('Error during image upload:', error);
                resetNodeData();
                return;
            }
        } else {
            console.error('Cannot find image_url');
            resetNodeData();
            return;
        }

        if (newPreviewUrl) {
            setPreviewUrl(newPreviewUrl);
            setNodeState(CustomNodeStatus.COMPLETED);

            const new_data = {
                ...data,
                status: CustomNodeStatus.COMPLETED,
                parameter_values: {
                    ...data.parameter_values,
                    image_id: newImageID,
                    image_thumbnail_url: newPreviewUrl
                }
            };
            data.onChange(new_data);
            setTimeout(() => {
                updateNodeInternals(data.id);
            }, 500);
        }
    };

    const readFileAsDataURL = (file: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(new Error('Error reading file'));
            reader.readAsDataURL(file);
        });
    };

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            await handleImageSubmit(file);
            setFileName(file.name);
        }
    };

    const calculateSHA1 = async (file: Blob): Promise<string> => {
        const buffer = await file.arrayBuffer();
        const digest = await crypto.subtle.digest('SHA-1', buffer);
        return bufferToHex(digest);
    };

    const bufferToHex = (buffer: ArrayBuffer): string => {
        return Array.from(new Uint8Array(buffer))
            .map(b => b.toString(16).padStart(2, '0'))
            .join('');
    };

    const nodeClassName = `${styles.customNode} ${styles[nodeState]}`;
    let outputHandles = data.output?.names?.map((outputName: string, index: number) => {
        setTimeout(() => {
            updateNodeInternals(data.id);
        }, 0);

        return (
            <CustomHandle
                key={`output-${index}`}
                type="source"
                position={Position.Right}
                id={`output-${outputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={true}
                style={{background: `${NodeManager.colorByHandleName(outputName)}`}}
                data-tooltip-id="ImageLoaderNodeToolTip"
                data-tooltip-content={outputName}
            />
        )
    });

    const ImageViewHandler = () => {
        if (!isImageError) {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && isModalOpen) {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);


    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = async (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
        if (file) {
            await handleImageSubmit(file);
            setFileName(file.name);
        }
    };

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };


    return (
        <div className={`${nodeClassName}`} ref={nodeRef}>
            <div className={`${styles.node_header} customDragHandle`} style={{cursor: 'grab'}}>
                <div className={styles.title}>{data.label}</div>
                <div className={styles.close_node} onClick={handleCloseClick}>
                    <img alt="close" src="/images/aiflow/icon_node_close.svg"/>
                </div>
            </div>

            <div className={styles.node_content}>
                {previewUrl ?
                    <ImageWithFallback key={data.id} src={previewUrl} alt="Uploaded"
                                       className={styles.custom_node_image}
                                       onError={() => {
                                       }}
                                       onClickZoom={ImageViewHandler}
                                       onClick={handleDropzoneClick}
                    />
                    :
                    <div className={styles.dropzone} onClick={handleDropzoneClick}
                         onDrop={handleDrop} onDragOver={handleDragOver}>
                        <div className={styles.upload_image_desc}>
                            <label htmlFor={data.id}>Drag your file here or <b>click to upload</b></label>
                        </div>
                    </div>
                }
                <input
                    id={data.id}
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{display: 'none',}}
                    onChange={handleImageChange}
                />

                <div className={styles.upload_progress_container}
                     style={{display: isUploading || uploadComplete ? 'flex' : 'none'}}>
                    <div className={styles.uploading_desc}>
                        {uploadComplete ? 'Uploaded' : 'Uploading'}
                    </div>
                    <div className={styles.upload_progress_box}>
                        <input type="text" readOnly value={fileName} className={styles.upload_input}/>
                        <div className={styles.progress_bar} style={{width: `${uploadProgress}%`}}></div>
                    </div>
                </div>


                {/*<div className={styles.line_container}>*/}
                {/*    <div className={styles.line_or}></div>*/}
                {/*    <div className={styles.or_text}>OR</div>*/}
                {/*    <div className={styles.line_or}></div>*/}
                {/*</div>*/}
                <div style={{height: '1px', backgroundColor: '#262C34', width: '100%', margin: '10px 0 20px 0'}}></div>

                <div className={styles.image_link_container}>
                    <input
                        type="text"
                        placeholder="Enter image link here"
                        value={imageUrlInput}
                        onChange={(e) => setImageUrlInput(e.target.value)}
                        className={styles.url_input}
                    />
                    <button onClick={handleImageUrlChange} className={styles.get_button}>Get</button>
                </div>
            </div>
            {outputHandles}
            <Tooltip id="ImageLoaderNodeToolTip" opacity="0.8"/>

            {isModalOpen && previewUrl && createPortal(
                <div className={`${styles.modal} ${isModalOpen ? styles.show : ''}`}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <span className={styles.close} onClick={closeModal}>&times;</span>
                        <DragZoomSingle
                            ImageURL={previewUrl}
                        />
                    </div>
                </div>,
                document.body
            )}
            <div className={styles.node_footer}></div>
        </div>
    );
};

export default memo(ImageLoaderComponent);
