import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {APISupremumSignInUsingGoogleCredential, useAuth} from '../services/authService';
import headerStyles from './PageHeader.module.scss';
import aiFlowStyles from './PageHeaderAIFlow.module.scss';
import SymbolImage from '../asset/logo_symbol.svg';
import routes from "../routes";
import {GoogleLogin} from "@react-oauth/google";

const menuItems = [
    {name: 'AI Flow', url: routes.AIFlow},
    // {name: 'Models', url: routes.modelList},
    {name: 'Pricing', url: routes.pricing},
    // {name: 'Evaluations', url: routes.evaluations},
    // {name: 'Datasets', url: routes.datasets},
    {name: 'API Docs', url: routes.apiDocs},
    {name: 'About', url: routes.about}
];

const PageHeader = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    const isAIFlowPage = location.pathname.startsWith(routes.AIFlow);

    const styles = isAIFlowPage ? aiFlowStyles : headerStyles;

    return (
        <header className={styles.header}>
            <div className={styles.wrapper}>
                <Link to='/' className={styles.noUnderline}>
                    <div className={styles.logo}>
                        <div className={styles.logoSymbol}><img src={SymbolImage} alt="symbol"/></div>
                        <div className={styles.logoLogo}><img src='/images/cnapsai_logo.svg' alt="logo"
                                                              style={{width: "130px"}}/></div>
                    </div>
                </Link>
                <div className={styles.menu}>
                    <ul className={styles.menu_list}>
                        {menuItems.map((item, index) => (
                            <li key={index} className={styles.menu_item}>
                                <Link className={styles.no_style_link} to={item.url}>{item.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.login}>
                    { auth && auth.user ? (
                        <>
                            <div onClick={auth.signOut} className={styles.login_signout}>
                                Sign Out
                            </div>
                        </>
                    ) : (
                        <>
                            <GoogleLogin
                                shape="rectangular"
                                text="signin_with"
                                theme="filled_black"
                                onSuccess={async (credentialResponse) => {
                                    if (credentialResponse) {
                                        try {
                                            // auth only, non-response data
                                            await APISupremumSignInUsingGoogleCredential(credentialResponse).then(response => {
                                                const user_data = response.data.user;
                                                auth.signIn({
                                                    ...user_data,
                                                    session_id: response.data.session_id
                                                });
                                                const isAiflowPath = location.pathname.startsWith('/ai-flow');
                                                const redirectUrl = isAiflowPath ? `${location.pathname}${location.search}` : routes.home;
                                                navigate(redirectUrl);
                                            });
                                        } catch (error) {
                                            console.error('Login failed', error);
                                        }
                                    } else {
                                        console.error('Login failed');
                                    }
                                }} onError={() => {
                                console.log('Login Failed');
                            }}
                            />
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}

export default PageHeader;
