import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {NodeProps, Position, useUpdateNodeInternals} from 'reactflow';
import styles from './DefaultCustomNode.module.scss';
import CustomHandle from './CustomHandle';
import {CustomNodeStatus, INodeData} from '../../interfaces/d';
import nodeManager from './NodeManager';
import {Tooltip} from 'react-tooltip';
import ImageWithFallback from './ImageFallBack';
import {createPortal} from 'react-dom';
import DragZoomSingle from "../DragZoomSingle";

const ImageViewNodeComponent: React.FC<NodeProps<INodeData>> = ({data}) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const nodeRef = useRef<HTMLDivElement>(null);
    const [nodeState, setNodeState] = useState<CustomNodeStatus>(data.status);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageError, setIsImageError] = useState(false);

    const handleCloseClick = () => {
        if (data.onClose) {
            data.onClose(data.id);
        }
    }

    useEffect(() => {
        // console.log(`ImageViewNodeComponent data.data change useEffect`, data);
        setIsImageError(false);
        if (data.result?.thumbnail_url) {
            setPreviewUrl(data.result?.thumbnail_url);
        }
    }, [data.data, data.status]);

    useEffect(() => {
        // setTimeout(() => {
            if (!nodeRef.current) return;
            switch (data.status) {
                case CustomNodeStatus.PREPARING:
                    nodeRef.current.className = `${styles.customNode} ${styles.preparing}`;
                    // setTimeout(() => {
                        data.onChange({
                            id: data.id,
                            status: CustomNodeStatus.PREPARING
                        });
                    // }, 0);
                    setPreviewUrl('');
                    break;
                case CustomNodeStatus.PROCESSING:
                    nodeRef.current.className = `${styles.customNode} ${styles.processing}`;
                    break;
                case CustomNodeStatus.COMPLETED:
                    nodeRef.current.className = `${styles.customNode} ${styles.completed}`;
                    break;
            }
        // }, 100);
    }, [data.status]);

    useEffect(() => {
        // setTimeout(() => {
        updateNodeInternals(data.id);
        // }, 0);
    }, [previewUrl]);

    const inputHandles = useMemo(() => data.input?.names?.map((inputName: string, index: number) => {
        // setTimeout(() => {
        updateNodeInternals(data.id);
        // }, 0);

        return (
            <CustomHandle
                key={`input-${index}`}
                type="target"
                position={Position.Left}
                id={`input-${inputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={1}
                style={{background: `${nodeManager.colorByHandleName(inputName)}`}}
                data-tooltip-id="ImageViewNodeToolTip"
                data-tooltip-content={inputName}
            />
        );
    }), [data.input]);

    const ImageViewHandler = () => {
        if (!isImageError) {
            setIsModalOpen(true);
        }
    };

    const ImageDownloadHandler = async () => {
        if (data.result?.url) {
            try {
                const response = await fetch(data.result.url);
                if (!response.ok) throw new Error('Network response was not ok');
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const fileName = data.result.url.substring(data.result.url.lastIndexOf('/') + 1);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Image download failed', error);
            }
        } else {
            console.error('Image URL not found');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && isModalOpen) {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const nodeClassName = `${styles.customNode} ${styles[nodeState]}`;
    return (
        <div className={`${nodeClassName}`} ref={nodeRef}>
            {inputHandles}
            <div className={`${styles.node_header} customDragHandle`} style={{cursor: 'grab'}}>
                <div className={styles.title}>{data.label}</div>
                <div className={styles.close_node} onClick={handleCloseClick}><img
                    src="/images/aiflow/icon_node_close.svg"/></div>
            </div>

            <div className={styles.node_content}>
                {previewUrl ? (
                    <>
                        <ImageWithFallback src={previewUrl} alt="Uploaded"
                                           className={styles.custom_node_image}
                                           onError={() => setIsImageError(true)}
                                           onClickZoom={ImageViewHandler}
                        />
                        <button className={styles.node_download_button} style={{border: '0'}}
                                onClick={ImageDownloadHandler}>Image Download
                        </button>
                    </>
                ) : (
                    <div className={styles.dropzone}>
                    </div>
                )}
            </div>
            <Tooltip id="ImageViewNodeToolTip" opacity="0.8"/>
            {isModalOpen && createPortal(
                <div className={`${styles.modal} ${isModalOpen ? styles.show : ''}`}
                     onClick={(e) => e.stopPropagation()}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <span className={styles.close} onClick={closeModal}>&times;</span>
                        <DragZoomSingle
                            ImageURL={data.result?.url}
                        />
                    </div>
                </div>,
                document.body
            )}
            <div className={styles.node_footer}></div>
        </div>
    );
};

export default memo(ImageViewNodeComponent);
