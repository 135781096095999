import React from 'react';
import styles from './PricingPage.module.scss';
import {Link} from "react-router-dom";
import routes from "../routes";

const PricingPage: React.FC = () => {
    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.pricing}>
                    <div className={styles.pricing_wrapper}>
                        <h1 className={styles.pricing_title}>Pricing Plans</h1>
                        <p className={styles.pricing_desc}>Choose the plan that best fits your needs.</p>
                        <div className={styles.pricing_cards}>
                            <div className={styles.pricing_card}>
                                <h2 className={styles.pricing_card_title}>Beta</h2>
                                <p className={styles.pricing_card_price}>Free</p>
                                <ul className={styles.pricing_card_list}>
                                    {/* <li>Free for 100 Inferences</li> */}
                                    <li>Unlimited # of Inference</li>
                                    <li>Efficient AI Models</li>
                                    <li>Broad AI Categories / Modalities</li>
                                    <li>Servers upon Availability</li>
                                </ul>
                                <Link className={styles.pricing_card_button} to={routes.AIFlow}>Get Started</Link>
                            </div>
                            {/* <div className={styles.pricing_card}>
                                <h2 className={styles.pricing_card_title}>Standard</h2>
                                <p className={styles.pricing_card_price}>TBD</p>
                                <ul className={styles.pricing_card_list}>
                                    <li>Unlimited # of Inference</li>
                                    <li>Efficient AI Models</li>
                                    <li>Broad AI Categories / Modalities</li>
                                    <li>Servers upon Availability</li>
                                </ul>
                                <button className={`${styles.pricing_card_button} ${styles.disable}`}>Coming Soon
                                </button>
                            </div> */}
                            {/* <div className={styles.pricing_card}>
                                <h2 className={styles.pricing_card_title}>Professional</h2>
                                <p className={styles.pricing_card_price}>TBD</p>
                                <ul className={styles.pricing_card_list}>
                                    <li>Unlimited # of Inference</li>
                                    <li>All AI Models</li>
                                    <li>All AI Categories / Modalities</li>
                                    <li>Dedicated Servers</li>
                                    <li>Fine-Tuning by User Data</li>
                                </ul>
                                <button className={`${styles.pricing_card_button} ${styles.disable}`}>Coming Soon
                                </button>
                            </div> */}
                            <div className={styles.pricing_card}>
                                <h2 className={styles.pricing_card_title}>Enterprise</h2>
                                <p className={styles.pricing_card_price}>TBD (Contact Us)</p>
                                <ul className={styles.pricing_card_list}>
                                    <li>All Models, Categories, and Modalities</li>
                                    <li>Our Expert Team Provides Optimal<br/>AI flow Design Support</li>
                                    <li>Integrating Proprietary AI Models<br/>for Enterprise Clients</li>
                                    <li>Optimized and Customized AI flow<br/>Pipeline Servers and Throughput</li>
                                </ul>
                                <Link className={styles.pricing_card_button} to={routes.about}>Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default PricingPage;
