import {ReactNode} from "react";
import {Connection} from "reactflow";

export interface UserFavorite {
    created: number;
    model_id: string;
    updated?: number;
    user_id?: string;
    description?: string;
    license?: string;
}

export interface UserProfile {
    avatar?: {
        original: string,
        thumbnail: string
    };
    home?: string;
    job?: string;
    location?: string;
    message?: string;
    name?: string;
    is_public?: boolean
}

export interface UserProfileSave {
    avatar?: File | null;
    home?: string;
    job?: string;
    location?: string;
    message?: string;
    name?: string;
    is_public?: boolean
}

export interface IUserContactMessage {
    email: string;
    name: string;
    message: string;
}

export interface UserStats {
    favorites: number;
    followers: number;
    following: number;
    models: number;
}

export interface User {
    id: string;
    session_id: string;
    is_public: boolean;
    stats: UserStats | null;
    profile: UserProfile | null;
}

export interface Account {
    created: number;
    email: string;
    id: string;
    password: string;
}

export interface ImageModel {
    created: number;
    file_name: string;
    url: string;
}

export interface ModelCategory {
    main: string;
    sub: string;
}

export interface ModelLicense {
    code: string;
    data: string;
    owner: string;
}

export interface IModelModel {
    category: ModelCategory;
    description: string;
    related_links: Record<string, string>;
    image_name: string;
    license: ModelLicense;
    id: string;
    likes: number;
    model_name: string;
    model_runner_type: string;
    title: string;
    try: number;
    year: number;
    favorites: boolean;
}

export interface RequestProcessModelGenAI {
    downscale_factor: number;
    height: number;
    model: string;
    negative_prompt: string;
    output_format: string;
    prompt: string;
    sampler_name: string;
    scale: number;
    seed: number;
    steps: number;
    width: number;
}

export interface ResponseDashboardData {
    account: Account;
    favorite: UserFavorite;
    transactions: [TransactionTransaction];
    user: User;
}

export interface TransactionTransaction {
    created: number,
    id: string,
    properties: Record<string, any>,
    type: TransactionType,
    user_id: string,
    is_public: boolean
}

export enum ErrorCode {
    NoError = 0,
    Error = 1
}

enum TransactionType {
    GenAI = "GenAI",
    SISR = "SISR"
}

export interface IAPIResponseFlowUpload {
    created: number;
    id: string;
    last_modified: number;
    name: string;
    nodes: any[];
    on_processing: boolean;
    process_node_id: string;
    user_id: string;
}

export interface IAPIResponse<T> {
    data: T;
    error: {
        code: number,
        msg: string
    };
    result: ErrorCode;
}

export interface IAPIResponseDataEmpty {
}

export interface IGetModelAPI {
    category_map: Record<string, string[]>;
    models: IModelModel[]
}

export interface IFlowAPIModeList {
    enabled: boolean;
    metadata: IModelModel;
    node_definition: IFlowNodeSource;
    properties: Record<string, any>;
}

export interface IFlowNodeList {
    items: IFlowAPIModeList[]
}

export interface Category {
    icon_image: string;
    include_string_from_model_metadata_name: string;
    subCategories?: { name: string; image: string }[];
}

export interface CategoryMap {
    [categoryName: string]: string[];
}

export interface CategorySelectorProps {
    categories: { [category: string]: Category };
    selectedMainCategory: string | null;
    onSelectMainCategory: (categoryName: string) => void;
}

export interface ModelsAndCategories {
    models: IModelModel[];
    categories: { [category: string]: Category };
}

export interface GenAIModels {
    filteredModels: IModelModel[];
}

export enum UploadModalStage {
    OFF,
    UPLOAD,
    UPLOAD_PROGRESS,
    GENERATE,
    GENERATE_PROCESS,
    SENT_MAIL
}

export enum GenerativeAIPageStage {
    MODELANDPROMPT,
    PROCESSING,
    RESULT
}

export enum ProfileEditModalStage {
    OFF,
    EDIT
}

export interface EmailSendRequest {
    email: string;
}

export interface EmailSendFormData {
    transaction_id: string;
    email: string;
}

export interface DragZoomType {
    ImageURL: string,
    ContainerSize: { w: number, h: number },
    GlobalScale: number,
    GlobalPos: { x: number, y: number },
    MaxScale: number,
    FactorStep: number
}

export interface DragZoomProps {
    ImageURL: string,
    ContainerSize: { w: number, h: number },
    GlobalScale: number,
    GlobalPos: { x: number, y: number },
    MaxScale: number,
    FactorStep: number,
    onStateChange: (newData: DragZoomType) => void
}

export type AuthContextType = {
    user: User | null;
    signIn: (userData: User) => void;
    signOut: () => void;
    isLoggedIn: boolean;
    loading: boolean;
};

export type AuthProviderProps = {
    children: ReactNode;
};

// Favorite Models API Response
export interface UserFavoriteModels {
    current_page: number,
    total_page: number,
    total_models: number,
    models_per_page: number,
    favorite_models: UserFavorite[]
}

export interface SignInResponse {
    data: {
        user: User,
        session_id: string;
    };
}

// About Page
export interface Experience {
    company: string;
    location: string;
    position: string;
}

export interface MemberProps {
    name: string;
    role: string;
    picture: string;
    experiences: Experience[];
}

export interface GenerativeAIModelListProps {
    setPageStage: (stage: GenerativeAIPageStage) => void;
}

export enum CustomNodeStatus {
    "PREPARING" = "preparing",
    "PROCESSING" = "processing",
    "COMPLETED" = "completed",
    "ERROR" = "error",
    "TIMEOUT" = "timeout"
}

export enum CustomNodeType {
    IMAGE_LOADER = "ImageLoader",
    IMAGE_VIEWER = "ImageViewer",
    TXT2IMG_INPUT = "Txt2ImgInput",
    AI_MODEL = "AI-Model",
    TEXT_INPUT = "TextInput",
    FLOAT_INPUT = "FloatInput",
    UNKNOWN = "UNKNOWN"
}

export enum CustomNodeGenAIModalState {
    OFF,
    PROMPT
}

export interface ModalForGenAINodeProps {
    state: CustomNodeGenAIModalState;
    data: any;
    onSave: (data: any) => void;
    onCancel: () => void;
}

export interface IFlowNodeSource {
    category: string,
    description: string;
    related_links: Record<string, string>;
    created: number,
    id: string,
    input?: any,
    output?: any,
    input_ids: string[],
    output_ids: string[],
    node_name: string,
    node_type: string,
    model_id: string,
    parameters: Record<string, any>,
    result: {},
    status: CustomNodeStatus
    ui: INodeDataUI
}

export interface INodeData {
    id: string;
    created: number;
    label: string;
    description: string;
    related_links: Record<string, string>;
    type: CustomNodeType;
    status: CustomNodeStatus;
    data: INodeDataProperty;
    ui: INodeDataUI;
    onChange: any;

    result?: any;
    input?: any;
    output?: any;
    parameters?: Record<string, Parameter>;
    parameter_values?: Record<string, any>;

    onClose?(id: string): void;
}

export interface IAPIPollFlowNodeData {
    id: string;
    category: string;
    created: number;
    input_ids: string[];
    node_name: string;
    node_type: string;
    output_ids: string[];
    parameters: Record<string, any>;
    result: Record<string, any>;
    status: CustomNodeStatus;
    ui: INodeDataUI;
}

export interface IAPIPollFlowData {
    "created": number,
    "id": string,
    "last_modified": number,
    "name": string,
    "nodes": IAPIPollFlowNodeData[],
    "on_processing": boolean,
    "process_node_id": string,
    "user_id": string
}

export interface IAPILoadFlow {
    "created": number,
    "id": string,
    "last_modified": number,
    "name": string,
    "nodes": IFlowNodeSource[],
    "user_id": string
}

export interface IAPIFlowListItem {
    "category_count": number,
    "created": number,
    "id": string,
    "last_modified": number,
    "model_count": number,
    "name": string,
    "node_count": number,
    "status": string,
    "user_id": string
}

export interface IAPILoadFlowList {
    "items": IAPIFlowListItem[],
    "page": {
        "current_page": 0,
        "page_size": 0,
        "total_item_count": 0,
        "total_page": 0
    }
}

export interface INodeDataUI {
    x: number;
    y: number;
    connection: Connection[];
}

export interface INodeDataImage {
    exist?: boolean;
    ext: string;
    image_id: string;
    url: {
        thumbnail_url: string;
        url: string;
    }
}

export interface INodeDataProperty {
    image?: INodeDataImage;
    parameters?: Map<string, string | number>;
}

export interface Parameter {
    description?: string;
    type: string;
    default: number | string;
    read_only: boolean;
    hidden: boolean;
}

export interface IEdgeMapping {
    [key: string]: { inputs: string[], outputs: string[] };
}

export interface IAPIImageURLByChecksum {
    exist: boolean,
    image_id: string,
    url?: {
        url: string,
        thumbnail_url: string
    }
}

export interface IAPIImageUploadResponse {
    image_id: string,
    url: {
        thumbnail_url: string,
        url: string
    }
}

