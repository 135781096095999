const routes = {
    home: '/',
    signIn: '/signin',
    signUp: '/signup',
    modelList: '/models',
    modelComparison: '/model-comparison',
    evaluations: '/evaluations',
    datasets: '/datasets',
    apiDocs: '/api-docs',
    about: '/about',
    dashboard: '/dashboard',
    "generative-ai": '/generative-ai',
    "AIFlow": '/ai-flow',
    pricing: '/pricing',
    healthCheck: '/health',
    privacyPolicy: '/privacy-policy',
};

export default routes;
